
import FameMapVue from "@/components/contact/Fame-Map.vue";
import FormBoxVue from "@/components/contact/Form-Box.vue";
import TitleSessionVue from "@/components/util/Title-Session.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "TheContact",
  components:{
    'title-session':TitleSessionVue,
    'frame-map':FameMapVue,
    'form-box':FormBoxVue
  },
  setup() {
    return {};
  },
});
