
import { defineComponent, ref } from "vue";
import ListBlogs from "./JS/ListBlogs.js";

export default defineComponent({
  setup() {
    //values
    const listBlogs =
      ref<{ path: string; title: string; description: string }[]>(ListBlogs);

    //functions
    const getImage = (picture: string) => {
      return require(`../../images/zdjeciaBlogi/${picture}`);
    };

    return { listBlogs, getImage };
  },
});
