
import TheFooter from "../src/components/util/The-Footer.vue";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import TheHeaderVue from "./components/navigation/The-Header.vue";

export default defineComponent({
  name: "App",
  components: {
    "the-footer": TheFooter,
    "the-navigation": TheHeaderVue,
  },
  setup() {
    //values
    const store = useStore();

    //functions
    store.dispatch("gallery/loadGalleries");
    store.dispatch("auth/tryLogin")

    return {};
  },
});
