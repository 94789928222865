
import { defineComponent, computed, watch, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import TheFullScreanImageVue from "@/components/gallery/The-FullScrean-Image.vue";

export default defineComponent({
  name: "TheGallery",
  components: {
    "full-screen-gallery": TheFullScreanImageVue,
  },
  setup() {
    //values
    const router = useRoute();
    const store = useStore();
    const images_full_screen: {
      index: number;
      fullscreen: boolean;
    } = reactive({
      index: 0,
      fullscreen: false,
    });

    //functions
    store.dispatch("gallery/loadSingleGallery", router.params.nazwa);

    const openFullScreen = (index: number, value: boolean) => {
      images_full_screen.index = index;
      images_full_screen.fullscreen = value;
    };

    const offFullScreen = (value: boolean) => {
      images_full_screen.fullscreen = value;
    };

    //computed
    const nameGallery = computed(() => {
      return router.params.nazwa;
    });

    const loadSingleGallery = computed(() => {
      return store.getters["gallery/loadSingleGallery"];
    });

    //wachers
    watch(nameGallery, () => {
      store.dispatch("gallery/loadSingleGallery", router.params.nazwa);
    });

    return {
      nameGallery,
      loadSingleGallery,
      images_full_screen,
      openFullScreen,
      offFullScreen,
    };
  },
});
