
import { defineComponent, ref } from "vue";
import ListSuccessJS from "./JS/ListSuccess";

export default defineComponent({
  setup() {
    const ListSuccessArrayJS =
      ref<{ number: number; title: string }[]>(ListSuccessJS);

    return { ListSuccessArrayJS };
  },
});
