
import { defineComponent } from 'vue'

export default defineComponent({
    name:"TitleHeader",
    setup () {
        

        return {}
    }
})
