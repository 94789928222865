
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import ListPanelAdminJS from "../navigation-list-gallery/ListAdmin.js";

export default defineComponent({
  setup() {
    //values
    const router = useRouter();
    const showGalleriesButton = ref<boolean>(false);
    const galleriesItems =
      ref<{ id: string; nazwa: string; namerouter: string }[]>(
        ListPanelAdminJS
      );

    //functions
    const showGalleries = () => {
      showGalleriesButton.value = !showGalleriesButton.value;
    };

    const showGalleriesMove = (value: boolean) => {
      showGalleriesButton.value = value;
    };

    const singleGallery = (nazwa: string) => {
      router.push({
        name: nazwa,
      });
    };

    return {
      showGalleriesMove,
      showGalleriesButton,
      showGalleries,
      galleriesItems,
      singleGallery,
    };
  },
});
