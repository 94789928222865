
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    //values
    const store = useStore();
    const router = useRoute();

    //functions
    store.dispatch("gallery/loadSingleGallery", router.params.name);
    const deleteImage = async (id: string) => {
      const response = await fetch(
        `https://projekt2.server.arturscibor.pl/removeImage?id=${id}`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${store.getters["auth/LoginPanel"].accessToken}`,
          },
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        console.log(responseData);
        return;
      }
      console.log(responseData);
      store.dispatch("gallery/loadSingleGallery", router.params.name);
    };

    //computed
    const loadSingleGallery = computed(() => {
      return store.getters["gallery/loadSingleGallery"];
    });

    return { loadSingleGallery, deleteImage };
  },
});
