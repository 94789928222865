
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["open-slide-bar"],
  setup(_, context) {
    //functions
    const openSlideBar = (value: boolean) => {
      context.emit("open-slide-bar", value);
    };

    return { openSlideBar };
  },
});
