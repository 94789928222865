
import { defineComponent, computed } from "vue";
import ListItemsVue from "./navigation-list-gallery/ListItems.vue";
import ListAdminPanel from "./navigation-list-gallery/ListAdminPanel.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "List-Links",
  components: {
    "list-items": ListItemsVue,
    "list-admin": ListAdminPanel,
  },
  setup() {
    //values
    const store = useStore()

    //functions
    const logout = () =>{
      store.dispatch('auth/logout')
    }

    //computed

    const loadAdminPanel = computed(()=> {
      const accessToken = store.getters['auth/LoginPanel'].accessToken
      const refreshToken = store.getters['auth/LoginPanel'].refreshToken

      if(accessToken === null || refreshToken === null){
        return false
      }
      return true
    })

    return {loadAdminPanel, logout};
  },
});
