export default[
    {
        path:'domek.png',
        title:'podstawowy',
        price: 150
    },
    {
        path:'wieżowiec.png',
        title:'premium',
        price: 650
    },
    {
        path:'dom_handlowy.png',
        title:'ultimate',
        price: 1500
    },
]