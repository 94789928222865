
import { computed, defineComponent, ref, provide } from "vue";
import { useStore } from "vuex";
import ButtonToggleVue from "./Button-Toggle.vue";
import ListLinksVue from "./List-Links.vue";
import SlideBarVue from "./Slide-Bar.vue";
import TitleHeaderVue from "./Title-Header.vue";

export default defineComponent({
  name: "The-Header",
  components: {
    "title-header": TitleHeaderVue,
    "list-links": ListLinksVue,
    "slide-bar": SlideBarVue,
    "button-toggle": ButtonToggleVue,
  },
  setup() {
    //values
    const store = useStore();
    const arrayLinksGalleries = ref<{ id: string; nazwa: string }[]>([]);
    const showSlideBar = ref<boolean>(false);
    const buttonToggle = ref<boolean>(false);

    //functions
    const openSlideBar = (value: boolean) => {
      showSlideBar.value = value;
    };

    const checkWidthScreen = () => {
      if (window.innerWidth <= 1000) {
        buttonToggle.value = true;
      } else {
        buttonToggle.value = false;
        openSlideBar(false);
      }
    };
    window.addEventListener("resize", checkWidthScreen);
    checkWidthScreen();

    //computed
    const loadGalleries = computed(() => {
      return store.getters["gallery/loadGalleries"];
    });

    //provides
    provide("galleries", loadGalleries);

    return { arrayLinksGalleries, showSlideBar, buttonToggle, openSlideBar };
  },
});
