import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53dad3c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delete__container__page" }
const _hoisted_2 = { class: "box__list" }
const _hoisted_3 = { class: "buttons__options" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadGalleries, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.id
        }, [
          _createElementVNode("p", null, _toDisplayString(item.nazwa), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "edit",
              onClick: ($event: any) => (_ctx.editGallery(item.nazwa))
            }, " Edytuj galerie ", 8, _hoisted_4),
            _createElementVNode("button", {
              class: "delete",
              onClick: _withModifiers(($event: any) => (_ctx.removeGallery(item.nazwa)), ["prevent"])
            }, " Usuń galerie ", 8, _hoisted_5)
          ])
        ]))
      }), 128))
    ])
  ]))
}