import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b050f95c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list__success" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ListSuccessArrayJS, (item, index) => {
      return (_openBlock(), _createElementBlock("li", { key: index }, [
        _createElementVNode("h2", null, _toDisplayString(item.number) + "+", 1),
        _createElementVNode("h3", null, _toDisplayString(item.title), 1)
      ]))
    }), 128))
  ]))
}