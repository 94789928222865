
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      required: true,
      type: String,
      defoult: "Brak Tytułu!",
    },
  },
  setup() {
    return {};
  },
});
