import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8ab3dba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_header = _resolveComponent("title-header")!
  const _component_list_links = _resolveComponent("list-links")!
  const _component_button_toggle = _resolveComponent("button-toggle")!
  const _component_slide_bar = _resolveComponent("slide-bar")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_title_header),
    (!_ctx.buttonToggle)
      ? (_openBlock(), _createBlock(_component_list_links, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.buttonToggle)
      ? (_openBlock(), _createBlock(_component_button_toggle, {
          key: 1,
          onOpenSlideBar: _ctx.openSlideBar
        }, null, 8, ["onOpenSlideBar"]))
      : _createCommentVNode("", true),
    (_ctx.showSlideBar)
      ? (_openBlock(), _createBlock(_component_slide_bar, {
          key: 2,
          onCloseSlideBar: _ctx.openSlideBar
        }, null, 8, ["onCloseSlideBar"]))
      : _createCommentVNode("", true)
  ]))
}