
import { defineComponent, inject, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ListAdmin from "./navigation-list-gallery/ListAdmin.js";

export default defineComponent({
  emits: ["close-slide-bar"],
  setup(_, context) {
    //values
    const router = useRouter();
    const store = useStore();
    const galleriesItems = ref(
      inject("galleries") as { id: string; nazwa: string }[]
    );
    const showGalleryLinks = ref<boolean>(false);
    const showAdmin = ref<boolean>(false);
    const listAdmin =
      ref<{ id: string; nazwa: string; namerouter: string }[]>(ListAdmin);

    //functions
    const closePanel = (value: boolean) => {
      context.emit("close-slide-bar", value);
    };

    const logout = () => {
      store.dispatch("auth/logout");
    };

    const singleGallery = (nazwa: string) => {
      router.push({
        name: "gallery",
        params: { nazwa: nazwa },
      });
    };

    const adminPanel = (name: string) => {
      router.push({
        name: name,
      });
    };

    const showGalleries = () => {
      showGalleryLinks.value = !showGalleryLinks.value;
    };
    const showAdminPanel = () => {
      showAdmin.value = !showAdmin.value;
    };

    //computed
    const loadAdminPanel = computed(() => {
      const accessToken = store.getters["auth/LoginPanel"].accessToken;
      const refreshToken = store.getters["auth/LoginPanel"].refreshToken;

      if (accessToken === null || refreshToken === null) {
        return false;
      }
      return true;
    });

    return {
      closePanel,
      galleriesItems,
      singleGallery,
      showGalleries,
      showGalleryLinks,
      logout,
      loadAdminPanel,
      listAdmin,
      showAdminPanel,
      showAdmin,
      adminPanel,
    };
  },
});
