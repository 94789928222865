import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06e5fd4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact__container_page" }
const _hoisted_2 = { class: "box__information" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_session = _resolveComponent("title-session")!
  const _component_frame_map = _resolveComponent("frame-map")!
  const _component_form_box = _resolveComponent("form-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_title_session, { title: "Skontaktuj się z nami" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_frame_map),
      _createVNode(_component_form_box)
    ])
  ]))
}