import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ae67c86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_admin = _resolveComponent("list-admin")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_list_items = _resolveComponent("list-items")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_ctx.loadAdminPanel)
      ? (_openBlock(), _createBlock(_component_list_admin, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, { to: { name: 'aboutus' } }, {
        default: _withCtx(() => [
          _createTextVNode("O nas")
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_list_items),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, { to: { name: 'contact' } }, {
        default: _withCtx(() => [
          _createTextVNode("Kontakt")
        ]),
        _: 1
      })
    ]),
    (_ctx.loadAdminPanel)
      ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)), ["prevent"]))
          }, "Wyloguj się")
        ]))
      : _createCommentVNode("", true)
  ]))
}