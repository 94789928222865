
import UploadContainerVue from "@/components/util/UploadContainer.vue";
import { defineComponent, computed, reactive, ref, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "AddImagesGallery",
  components: {
    "uploader-container": UploadContainerVue,
  },
  setup() {
    //values
    const store = useStore();
    const valuesGallery = reactive({
      name: "",
      select_name: "",
      select_id: "",
    });
    const arrayGallery = ref<{ id: string; nazwa: string }[]>([]);
    const arrayImagesUploader = ref<{}[]>([]);
    const error = ref<string>("");
    const reloadUploader = ref<boolean>(false);

    //functions

    const restartError = () => {
      setTimeout(() => {
        error.value = "";
      }, 5000);
    };

    const loadGallery = () => {
      const galleries = store.getters["gallery/loadGalleries"];
      for (const image of galleries) {
        arrayGallery.value.push(image);
      }
    };
    loadGallery();

    const imagesUploader = (value: {}[]) => {
      arrayImagesUploader.value = value
    };

    const submitForm = async () => {
      if (valuesGallery.name === "" && valuesGallery.select_name === "") {
        error.value = "Wybierz typ dodania galerii!";
        restartError();
        return;
      }
      if (valuesGallery.name !== "" && valuesGallery.select_name !== "") {
        error.value = "Możesz tylko jedną z opcji";
        restartError();
        return;
      }

      if (valuesGallery.name === "") {
        const findId = arrayGallery.value.filter(
          (gallery) => gallery.nazwa == valuesGallery.select_name
        );
        valuesGallery.select_name = findId[0].nazwa;
        valuesGallery.select_id = findId[0].id;
      }

      const FD = new FormData();
      FD.append(
        "folder_image",
        `${valuesGallery.name || valuesGallery.select_name}`
      );
      let img: any;
      for (img of arrayImagesUploader.value) {
        FD.append("images", img);
      }

      const url = `https://projekt2.server.arturscibor.pl/addGalery?name_category=${
        valuesGallery.name || valuesGallery.select_name
      }`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          authorization: `Bearer ${store.getters["auth/LoginPanel"].accessToken}`,
        },
        body: FD,
      });
      const responseData = await response.json();
      if (!response.ok) {
        error.value = responseData.error;
        restartError();
        return;
      }
      error.value = responseData.succes;
      restartError();
      valuesGallery.name = "";
      valuesGallery.select_name = "";
      valuesGallery.select_id = "";
      reloadUploader.value = true;
      store.dispatch("gallery/loadGalleries");
    };

    //computed
    const loadGalleries = computed(() => {
      return store.getters["gallery/loadGalleries"];
    });

    const checkInputs = computed(() => {
      if (arrayImagesUploader.value.length === 0) {
        return true;
      }
      return false;
    });

    //wachers
    watch(loadGalleries, () => {
      loadGallery();
    });

    return {
      loadGalleries,
      submitForm,
      valuesGallery,
      arrayGallery,
      error,
      imagesUploader,
      reloadUploader,
      checkInputs,
    };
  },
});
