
import { defineComponent } from "vue";

export default defineComponent({
  name: "ItemInformationImage",
  props: {
    image: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
  },
  setup() {
    //functions
    const getPicture = (picture: string) => {
      return require(`../../images/6ikony/${picture}`);
    };
    return { getPicture };
  },
});
