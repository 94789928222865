
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    //values
    const store = useStore();
    const router = useRouter();

    const editGallery = (name: string) => {
      router.push({
        name: "editgallery",
        params: { name: name },
      });
    };

    const removeGallery = async (name: string) => {
      const url = `https://projekt2.server.arturscibor.pl/removeGallery?name_category=${name}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          authorization: `Bearer ${store.getters["auth/LoginPanel"].accessToken}`,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        console.log(responseData);
        return;
      }
      console.log(responseData);
      store.dispatch("gallery/loadGalleries");
    };

    //computed
    const loadGalleries = computed(() => {
      return store.getters["gallery/loadGalleries"];
    });

    return { loadGalleries, removeGallery, editGallery };
  },
});
