import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-872fb93a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aboutus__container__page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_session = _resolveComponent("title-session")!
  const _component_information_image = _resolveComponent("information-image")!
  const _component_list_success = _resolveComponent("list-success")!
  const _component_list_price = _resolveComponent("list-price")!
  const _component_list_blogs = _resolveComponent("list-blogs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_title_session, { title: "Czym zajmujemy się na codzień?" }),
    _createVNode(_component_information_image),
    _createVNode(_component_title_session, { title: "Nasze osiągnięcia" }),
    _createVNode(_component_list_success),
    _createVNode(_component_title_session, { title: "Nasze ceny" }),
    _createVNode(_component_list_price),
    _createVNode(_component_title_session, { title: "Blogi" }),
    _createVNode(_component_list_blogs)
  ]))
}