
import { defineComponent, ref } from "vue";
import ListPrice from "./JS/ListPrice.js";

export default defineComponent({
  setup() {
    //values
    const listPrice =
      ref<{ path: string; title: string; price: number }[]>(ListPrice);

    //functions
    const getImage = (picture: string) => {
      return require(`../../images/3ikony/${picture}`);
    };

    return { listPrice, getImage };
  },
});
