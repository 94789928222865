
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "UploaderContainer",
  emits: ["array-images"],
  props: {
    type: {
      required: true,
      type: Boolean,
    },
    restartUploader: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, ctx) {
    //values
    const arrayImages = ref<File[]>([]);
    const uploader = ref<any>(null);

    //functions
    const addArrayImages = (e: InputEvent) => {
      const images = (e.target as HTMLInputElement).files as FileList;
      for (const image of images) {
        arrayImages.value.push(image);
      }
      ctx.emit("array-images", arrayImages.value);
      uploader.value.value = "";
    };

    const deleteImage = (image: number) => {
      const index = arrayImages.value.findIndex(
        (id) => id.lastModified === image
      );
      arrayImages.value.splice(index, 1);
      ctx.emit("array-images", arrayImages.value);
    };

    const convertSize = (size: number) => {
      return (size / (1024 * 1024)).toFixed(2) + "-MB";
    };

    const restartUploader = () => {
      arrayImages.value = [];
      uploader.value.value = "";
    };

    //computed
    const loadArrayImages = computed(() => {
      return arrayImages.value;
    });

    //watchers
    watch(
      () => props.restartUploader,
      () => {
        restartUploader();
      }
    );

    return {
      addArrayImages,
      arrayImages,
      loadArrayImages,
      convertSize,
      deleteImage,
      uploader,
    };
  },
});
