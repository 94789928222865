import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04b5b55c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item__list" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.getPicture(_ctx.image),
        alt: "photo__icon"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.name), 1),
        _createTextVNode(" - " + _toDisplayString(_ctx.description), 1)
      ])
    ])
  ]))
}