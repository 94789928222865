export default[
    {
        number: 10,
        title: 'Lat na rynku'
    },
    {
        number: 1500,
        title: 'Wykonanych projektów'
    },
    {
        number: 1000,
        title: 'Zadowolonych klientów'
    },
    {
        number: 450,
        title: 'Zatrudnieni pracownicy'
    },
]