
import InformationImageVue from "@/components/aboutas/InformationImage.vue";
import ListBlogs from "@/components/aboutas/ListBlogs.vue";
import ListPrice from "@/components/aboutas/ListPrice.vue";
import ListSuccess from "@/components/aboutas/ListSuccess.vue";
import TitleSessionVue from "@/components/util/Title-Session.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AboutUs",
  components: {
    "title-session": TitleSessionVue,
    "information-image": InformationImageVue,
    "list-success": ListSuccess,
    "list-price":ListPrice,
    'list-blogs':ListBlogs
  },
  setup() {
    return {};
  },
});
