import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10e7445a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "edit__container__page" }
const _hoisted_2 = { class: "list__images" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadSingleGallery, (image, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createElementVNode("img", {
            src: 'https://projekt2.server.arturscibor.pl/' + image.path
          }, null, 8, _hoisted_3),
          _createElementVNode("button", {
            onClick: _withModifiers(($event: any) => (_ctx.deleteImage(image.id)), ["prevent"])
          }, "Usuń zdjęcie", 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}