
import { defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import LoadingSpinner from "../components/util/BaseSpinner.vue";

export default defineComponent({
  name: "LoginPanel",
  components: {
    "loading-spinner": LoadingSpinner,
  },
  setup() {
    //values
    const store = useStore();
    const router = useRouter();
    const login_panel = reactive<{ login: string; password: string }>({
      login: "",
      password: "",
    });
    const loadingSpinner = ref<boolean>(false);

    //functions
    const submit = async () => {
      const url = `https://projekt2.server.arturscibor.pl/authentification?login=${login_panel.login}&password=${login_panel.password}`;

      loadingSpinner.value = true;
      const response = await fetch(url, {
        method: "POST",
      });
      const responseData = await response.json();
      if (!response.ok) {
        console.log(responseData);
        loadingSpinner.value = false;
        return;
      }
      console.log(responseData);
      loadingSpinner.value = false;
      store.dispatch("auth/LoginPanel", responseData);
      router.push({
        name: "addimagegallery",
      });
      login_panel.login = "";
      login_panel.password = "";
    };

    return { submit, login_panel, loadingSpinner };
  },
});
