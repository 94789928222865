
import { defineComponent, ref, inject } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    //values
    const router = useRouter();
    const showGalleriesButton = ref<boolean>(false);
    const galleriesItems = ref(
      inject("galleries") as { id: string; nazwa: string }[]
    );

    //functions
    const showGalleries = () => {
      showGalleriesButton.value = !showGalleriesButton.value;
    };

    const showGalleriesMove = (value: boolean) => {
      showGalleriesButton.value = value;
    };

    const singleGallery = (nazwa: string) => {
      router.push({
        name: "gallery",
        params: { nazwa: nazwa },
      });
    };

    return {
      showGalleriesMove,
      showGalleriesButton,
      showGalleries,
      galleriesItems,
      singleGallery,
    };
  },
});
