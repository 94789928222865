
import { defineComponent, ref } from "vue";
import ItemInformationImageVue from "./ItemInformationImage.vue";
import InformationImageJS from "./JS/ListInformationImage.js";

export default defineComponent({
  name: "InformationImage",
  components: {
    "item-information-image": ItemInformationImageVue,
  },
  setup() {
    const InformationImageJsArray =
      ref<{ path: string; name: string; description: string }[]>(
        InformationImageJS
      );
    return { InformationImageJsArray };
  },
});
