export default[
    {
        id:'1',
        nazwa:'Dodaj zdjęcie/galerie',
        namerouter: 'addimagegallery'
    },
    {
        id:'2',
        nazwa:'Usuń/Edycja zdjęcie/galerie',
        namerouter: 'deletegallery'
    },
]